export default () => {
  const { $gtm, $posthog, $intercom } = useNuxtApp()

  const trackSubmitContactForm = () => {
    $gtm.push({
      event: 'submittedContactForm',
    })

    $posthog.capture('contact_form_submitted')
  }

  const trackCreateReferralCode = () => {
    $posthog.capture('referral_code_created')
    $intercom.call('trackEvent', 'referral-code-created')
  }

  const trackCancelSubscription = () => {
    $posthog.capture('subscription_cancellation_sent')
    $intercom.call('trackEvent', 'subscription-cancellation-sent')
  }

  const trackSubscriptionActivationFailure = ({
    errorMessage = 'Unknown error',
    planPriceId,
  }: {
    errorMessage?: string
    planPriceId: string
  }) => {
    $intercom.call('trackEvent', 'activation-failure', { planPriceId, errorMessage })
  }

  return {
    trackSubmitContactForm,
    trackCreateReferralCode,
    trackCancelSubscription,
    trackSubscriptionActivationFailure,
  }
}
